import React from 'react';
import style from '../TopScreenVideo/index.module.scss';
import video from '../../assets/videos/stelki.mp4';
import SignOnline from '../SignOnline/SignOnline.jsx';

export default function TopScreenVideo({ landingData, toggleModal }) {
	// console.log('landingData', landingData);
	return (
		<section className={style.topScreenVideo}>
			<div className={style.containerVideo}>
				<video
					className={style.backgroundVideo}
					loop
					muted
					autoPlay
					playsInline
					disablePictureInPicture
					preload='auto'
				>
					<source src={video} type='video/mp4' />
				</video>
			</div>
			<div className={style.inner}>
				<div className={style.title}>
					Как избавиться от 19 проблем с помощью индивидуальных ортопедических
					стелек?
				</div>
				<div className={style.appeal}>
					<div className={style.content}>
						<div className={style.stock}>
							<div className={style.actionTitle}>Акция!</div>
							<p className={style.text}>
								Первая пара{' '}
								<span className={style.striked}>
									{landingData?.head[1]?.value}
								</span>
								<span className={style.marked}>
									<b> {landingData?.head[2]?.value} </b>
								</span>
								руб.
								<br />
								Вторая пара и последующая
								<br />
								по тому же слепку
								<span className={style.marked}>
									{' '}
									<span className={style.striked}>
										{landingData?.head[2]?.value}
									</span>{' '}
									<b>{landingData?.head[0]?.value}</b>{' '}
								</span>{' '}
								руб.
								<br />
								<br />
								{/* <b>Все дорожает - успей заказать по СТАРОЙ ЦЕНЕ</b> */}
								<br />
								<br />
								10% скидка при изготовлении от 2 человек
								<br />
								на первую пару
							</p>
						</div>
						<div className={style.plus}>+</div>
						<div className={style.helper}>
							<p className={style.bonus}>
								Бесплатная консультация
								<br />
								ортопеда в подарок!
							</p>
						</div>
					</div>
				</div>
				<div className={style.wrapper__buttons}>
					<div className={style.button} onClick={toggleModal}>
						Записаться на приём
					</div>
					<SignOnline landingData={landingData} inline={true} />
				</div>
				<div className={style.footnote}>
					*В связи с большим количеством пациентов, просим вас записываться на
					изготовление стелек заранее
				</div>
			</div>
		</section>
	);
}
